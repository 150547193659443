<template>
  <div class="wrap">
    <select
      @change="(e) => select(e.target.value)"
      data-test="world-selector"
      class="selector"
      >
      <option
        v-for="world in worlds"
        :key="world.id"
        :value="world.id"
        :selected="activeWorld && activeWorld.id === world.id"
      >{{ world.name }}
      </option>
    </select>
    <p v-if="0" data-test="world-selector-active">
      {{ activeWorld.name }}
    </p>
    <ul v-if="0">
      <li
        data-test="world-selector-option"
        v-for="world in worlds"
        :key="world.id"
        @click="select(world.id)"
      >
        {{ world.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    worlds: { type: Array, required: true },
    activeWorld: { type: Object, default: null },
  },
  methods: {
    select(id) {
      this.$emit('select', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 8px 24px;
  border-top: 1px solid var(--color-menu-border);
  border-bottom: 1px solid var(--color-menu-border);
}
.selector {
  @include text-menu;
  background: none;
  border: 0;
  color: var(--color-text);;
  outline: none;
  height: 32px;
  width: 100%;
  box-sizing: border-box;
}
</style>
