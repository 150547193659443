import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import FetchData from '@/helpers/fetch-data';
import App from './App.vue';
import router from './router';
import store from './store';
import makeServer from '../server/loader';

if (0 && makeServer && !window.Cypress) {
  makeServer();
}

const app = createApp(App);
app.component('fetch-data', FetchData);
app.use(store).use(router).use(PrimeVue)
  .mount('#app');
