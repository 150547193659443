<template>
  <div class="app" :data-theme="currentTheme">
    <template v-if="loggedIn">
      <template v-if="loaded">
        <main-layout v-if="hasWorlds" />
        <welcome-modal v-else />
      </template>
    </template>
    <login-layout v-else />
  </div>
</template>

<script>
import './style/reset.scss';
import './style/colors.scss';
import WelcomeModal from './components/WelcomeModal.vue';
import MainLayout from './views/layouts/MainLayout.vue';
import LoginLayout from './views/layouts/Login.vue';

export default {
  components: { WelcomeModal, MainLayout, LoginLayout },
  data() {
    return {
      loaded: false,
    };
  },
  watch: {
    loggedIn: {
      handler(val) {
        if (val) {
          this.$store.dispatch('worlds/all').then(() => {
            this.loaded = true;
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
    hasWorlds() {
      return this.worlds.length > 0;
    },
    worlds() {
      return this.$store.getters['worlds/all'];
    },
    currentTheme() {
      return this.$store.getters['ui/theme'];
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
body {
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.app {
  color: var(--color-text);
}
</style>
