import { createServer, Response } from 'miragejs';
import makeServer from './index';

export default makeServer;

if (window.Cypress) {
  // If your app makes requests to domains other than / (the current domain), add them
  // here so that they are also proxied from your app to the handleFromCypress function.
  // For example: let otherDomains = ["https://my-backend.herokuapp.com/"]
  const domains = ['/'];
  const methods = ['get', 'put', 'patch', 'post', 'delete'];

  createServer({
    environment: 'test',
    routes() {
      // eslint-disable-next-line no-restricted-syntax
      domains.forEach((domain) => {
        methods.forEach((method) => {
          this[method](`${domain}*`, async (schema, request) => {
            const [status, headers, body] = await window.handleFromCypress(
              request,
            );
            return new Response(status, headers, body);
          });
        });
      });
    },
  });
}
