import axios from 'axios';

export default function api(store) {
  const existingToken = window.localStorage.getItem('access_token');
  if (existingToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${existingToken}`;
  }

  store.subscribe(({ type, payload }) => {
    if (type === 'auth/setToken') {
      console.log('Set axios token', payload);
      axios.defaults.headers.common.Authorization = `Bearer ${payload}`;
    }

    if (type === 'worlds/select') {
      axios.defaults.headers.common['world-id'] = payload;
    }
  });
}
