import axios from 'axios';

export default {
  namespaced: true,
  state: {
    all: [],
    active: null,
  },
  getters: {
    all(state) { return state.all; },
    active(state) { return state.all.find((item) => item.id === state.active); },
  },
  mutations: {
    setAll(state, all) {
      state.all = all;
    },
    add(state, item) {
      state.all.push(item);
    },
    select(state, id) {
      state.active = id;
    },
  },
  actions: {
    async all({ state, commit }) {
      const { data: { worlds } } = await axios.get('/worlds');
      commit('setAll', worlds);
      if (!state.active && worlds.length > 0) {
        commit('select', worlds[0].id);
      }
    },
    async create({ commit }, name) {
      const { data } = await axios.post('/worlds', { name });
      commit('add', data.world);
    },
  },
};
