<template>
  <div class="wrap">
    <form class="form" @submit.prevent="login">
      <h2 class="title">Login</h2>
      <input class="input" v-model="email" type="email" placeholder="Email">
      <input class="input" v-model="password" type="password" placeholder="Password">
      <Button type="submit" state="success">Login</Button>
    </form>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  components: {
    Button,
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    login() {
      this.$store.dispatch('auth/login', {
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  padding: 24px 16px;
  border-radius: 4px;
  background: var(--color-card-background);
  max-width: 400px;
}

.title {
  @include text-headline2;
  color: var(--color-text);
  margin-bottom: 16px;
}

.input {
  @include text-body;
  border: 0;
  background: var(--color-input-background);
  color: var(--color-text);
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  box-sizing: border-box;
  margin-bottom: 16px;

  &::placeholder {
    color: var(--color-input-placeholder-text);
  }
}
</style>
