<template>
  <div>
    Home
  </div>
</template>

<script>

export default {
  name: 'Home',
};
</script>
