export default {
  data() {
    return {
      loading: {},
      ids: {},
      meta: {},
    };
  },
  props: {
    get: { default: null },
    combine: { type: Boolean, default: false },
  },
  emits: ['loaded'],
  computed: {
    getArray() {
      return Array.isArray(this.get) ? this.get : [this.get];
    },
    getString() {
      return JSON.stringify(this.get);
    },
    isLoading() {
      let loading = false;
      Object.keys(this.loading).forEach((item) => {
        if (this.loading[item] === 'loading') loading = true;
      });
      return loading;
    },
    isFailed() {
      let loading = false;
      Object.keys(this.loading).forEach((item) => {
        if (this.loading[item] === 'failed') loading = true;
      });
      return loading;
    },
    getterData() {
      const data = {};
      this.getArray.forEach(({ attrs = {}, ...item }) => {
        const name = Object.keys(item)[0];
        const getter = item[name];
        const attrString = this.stringifyAttrs(attrs);
        const loadString = `${getter}?${attrString}`;
        // We're merging this way because the getter might provide ids in a sorting
        const args = { ids: this.ids[loadString], ...attrs };
        data[name] = Object.keys(args).length
          ? this.$store.getters[getter](args)
          : this.$store.getters[getter];
        data[`${name}_meta`] = this.meta[loadString];
      });
      return data;
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    getString() {
      this.fetchData();
    },
    isLoading(now) {
      if (!now) {
        this.$emit('loaded', this.getterData);
      }
    },
  },
  methods: {
    fetchData() {
      this.getArray.forEach((item) => {
        this.fetchItem(item);
      });
    },
    async fetchItem({ attrs = {}, ...item }) {
      const name = Object.keys(item)[0];
      const getter = item[name];
      const fetcher = `${getter}_fetch`;

      const attrString = this.stringifyAttrs(attrs);
      const loadString = `${getter}?${attrString}`;
      const loadingStatus = this.loading[loadString] === 'success' ? 'updating' : 'loading';
      this.loading[loadString] = loadingStatus;

      try {
        const { items, meta } = await this.$store.dispatch(fetcher, attrs);
        this.ids[loadString] = items.map((dataItem) => dataItem.id);
        this.meta[loadString] = meta;
        this.loading[loadString] = 'success';
      } catch (e) {
        console.warn('Fetch data failed: ', e);
        this.loading[loadString] = 'failed';
      }
    },
    stringifyAttrs(attrs) {
      return Object.keys(attrs).map((key) => `${key}:${attrs[key]}`).join('&');
    },
    pushId(id) {
      const item = this.getArray[0];
      const name = Object.keys(item)[0];
      const getter = item[name];
      const attrString = this.stringifyAttrs(item.attrs);
      const loadString = `${getter}?${attrString}`;
      this.ids = {
        ...this.ids,
        [loadString]: [...this.ids[loadString], id],
      };
    },
  },
  render() {
    const slotData = {
      ...this.getterData,
      _pushId: this.pushId,
    };

    if (this.combine) {
      return this.$slots.default({
        ...slotData,
        _loading: this.isLoading,
        _failed: this.isFailed,

      });
    }
    if (this.isLoading) {
      return this.$slots.loading ? this.$slots.loading() : null;
    }
    if (this.isFailed) {
      return this.$slots.failed ? this.$slots.failed() : null;
    }
    return this.$slots.default(slotData);
  },
};
