import axios from 'axios';

const SAVE_DELAY = 1000;
const updateClock = {};

export default {
  namespaced: true,
  state: {
    all: {},
    saveStack: {},
  },
  getters: {
    all(state) { return state.all; },
    single(state) {
      return ({ id }) => state.all[id];
    },
    type(state) {
      return ({ type, ids }) => {
        if (ids) {
          return ids.map((id) => state.all[id]);
        }
        return Object.values(state.all).filter((item) => item.type === type);
      };
    },
    mentions(state) {
      return ({ ids }) => {
        if (!ids) {
          return [];
        }
        return ids.map((id) => state.all[id]);
      };
    },
    ids(state) {
      return ({ ids }) => ids.map((id) => state.all[id]);
    },
  },
  mutations: {
    addList(state, list) {
      const newObj = {};
      list.forEach((item) => {
        const existing = state.all[item.id];
        if (existing) {
          // Check for update time
          if (!existing.updatedAt || existing.updatedAt < item.updatedAt) {
            console.log('Updating store article ', item.id);
            // TODO item.sections is a hack, item.sections should be sent as null from BE
            newObj[item.id] = { ...existing, ...item, sections: { ...item.sections } };
          }
        } else {
          newObj[item.id] = item;
        }
      });
      state.all = {
        ...state.all,
        ...newObj,
      };
    },
    update(state, { id, ...fieldsToUpdate }) {
      const fields = { ...fieldsToUpdate };
      if (fields.d_section) {
        const { id: sectionId, attrs: sectionAttrs } = fields.d_section;
        fields.sections = {
          ...state.all[id].sections,
          [sectionId]: {
            ...state.all[id].sections[sectionId],
            ...sectionAttrs,
          },
        };
        delete fields.d_section;
      }
      if (fields.d_sections) {
        const sections = { ...state.all[id].sections };
        fields.d_sections.forEach(({ id: sectionId, attrs: sectionAttrs }) => {
          sections[sectionId] = {
            ...state.all[id].sections[sectionId],
            ...sectionAttrs,
          };
        });
        fields.sections = sections;
        delete fields.d_sections;
      }
      state.all = {
        ...state.all,
        [id]: { ...state.all[id], ...fields, updatedAt: (new Date()).toISOString() },
      };
    },
    updateSaveStack(state, { id, ...rest }) {
      const fieldsToUpdate = state.saveStack[id] ? { ...state.saveStack[id], ...rest } : rest;
      state.saveStack = {
        ...state.saveStack,
        [id]: fieldsToUpdate,
      };
      localStorage.setItem('save_stack', JSON.stringify(state.saveStack));
    },
    removeFromSaveStack(state, id) {
      const newStack = { ...state.saveStack };
      delete newStack[id];
      state.saveStack = newStack;
      localStorage.setItem('save_stack', JSON.stringify(state.saveStack));
    },
  },
  actions: {
    async type_fetch({ commit }, {
      type, page, search, parent, ancestor, relation, relationId,
    }) {
      const { data } = await axios.get('/articles', {
        params: {
          type, page, search, parent, ancestor, relation, relationId,
        },
      });
      commit('addList', data.articles);
      return { items: data.articles, meta: data.meta };
    },
    async mentions_fetch({ commit }, { id }) {
      const { data } = await axios.get(`/mentions/${id}`);
      commit('addList', data.articles);
      return { items: data.articles, meta: data.meta };
    },
    async single_fetch({ commit }, { id }) {
      const { data } = await axios.get(`/articles/${id}`);
      commit('addList', [data.article]);
      return { items: [data.article], meta: data.meta };
    },
    async ids_fetch({ commit }, { ids }) {
      const { data } = await axios.get('/articles', { params: { ids } });
      commit('addList', data.articles);
      return { items: data.articles, meta: data.meta };
    },
    async create({ commit }, {
      title, type, parent, relation, relationId,
    }) {
      const { data } = await axios.post('/articles', {
        title, type, parent, relation, relationId,
      });
      commit('addList', [data.article]);
      return data.article;
    },
    async addSection({ commit }, {
      id, after, type, ...other
    }) {
      const { data } = await axios.post(`/articles/${id}/sections`, { after, type, ...other });
      commit('addList', [data.article]);
      return data.article;
    },
    async removeSection({ commit }, {
      id, sectionId,
    }) {
      const { data } = await axios.delete(`/articles/${id}/sections/${sectionId}`);
      commit('addList', [data.article]);
      return data.article;
    },
    async update({ commit, dispatch }, { id, ...fieldsToUpdate }) {
      commit('update', { id, ...fieldsToUpdate });
      commit('updateSaveStack', { id, ...fieldsToUpdate });
      if (!updateClock[id]) updateClock[id] = {};
      const { lastCall, timeout } = updateClock[id];
      if (timeout) { clearTimeout(timeout); }
      const delay = !lastCall || Date.now() - lastCall > SAVE_DELAY ? 0 : SAVE_DELAY;
      updateClock[id].timeout = setTimeout(() => {
        updateClock[id].lastCall = Date.now();
        dispatch('triggerSave', id);
      }, delay);
    },
    async triggerSave({ state, commit }, id) {
      const fieldsToUpdate = state.saveStack[id];
      commit('removeFromSaveStack', id);
      if (fieldsToUpdate) {
        axios.put(`/articles/${id}`, fieldsToUpdate).catch(() => {
          console.warn('Saving failed. Todo: push back to save stack', fieldsToUpdate);
        });
      }
    },
    async search({ commit }, query) {
      const { data } = await axios.get('/articles', {
        params: { search: query.toLowerCase() },
      });
      commit('addList', data.articles);
      return data.articles;
    },
  },
};
