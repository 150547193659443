import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/worlds/:id',
    name: 'world',
    component: () => import('../views/worlds/single.vue'),
  },
  {
    path: '/category/:type',
    name: 'category',
    component: () => import('@/views/articles/Category.vue'),
  },
  {
    path: '/articles/:id',
    name: 'article',
    component: () => import('@/views/articles/ArticleLoader.vue'),
  },
  {
    path: '/mapmaker',
    name: 'map',
    component: () => import('@/views/map/Test.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
