<template>
  <button :class="rootClass"><slot /></button>
</template>

<script>
export default {
  props: {
    state: { type: String, default: '' },
  },
  computed: {
    rootClass() {
      return {
        button: true,
        'button--success': this.state === 'success',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  @include text-button;
  background: var(--color-text);
  border-radius: 4px;
  border: 0;
  height: 40px;
  padding: 0 12px;
  cursor: pointer;
}

.button--success {
  background: var(--color-button-success-background);
  color: var(--color-button-success-text);

  &:hover {
    background: var(--color-button-success-background-hover);
    color: var(--color-button-success-text-hover);
  }
}
</style>
