<template>
  <aside class="navigation">
    <div class="logowrap">
      <img src="@/assets/logo.svg" />
    </div>
    <div class="selector">
      <world-selector
        :worlds="worlds"
        :active-world="activeWorld"
        @select="selectWorld"
      />
    </div>

    <nav data-test="navigation">
      <ul class="links">
        <li>
          <router-link :to="{ name: 'Home' }">
            <span class="link-icon material-icons-outlined">home</span>
            Home
          </router-link>
        </li>
        <li v-for="category in categories" :key="category.key">
          <router-link
            :to="{ name: 'category', params: { type: category.key } }"
            :class="{active: currentCategory === category.key}"
          >
            <span class="link-icon material-icons-outlined">{{ category.icon }}</span>
            {{ category.label }}
          </router-link>
        </li>
      </ul>
    </nav>

    <div class="themeselect">
      <button @click="toggleTheme('default')" class="theme theme-default"></button>
      <button @click="toggleTheme('dark')" class="theme theme-dark"></button>
      <button @click="toggleTheme('purple')" class="theme theme-purple"></button>
    </div>
  </aside>
</template>

<script>
import WorldSelector from '@/components/WorldSelector.vue';

export default {
  components: { WorldSelector },
  computed: {
    activeWorld() {
      return this.$store.getters['worlds/active'];
    },
    categories() {
      return this.$store.getters['categories/all'];
    },
    worlds() {
      return this.$store.getters['worlds/all'];
    },
    currentCategory() {
      const { name, params } = this.$route;
      if (name === 'article') {
        const article = this.$store.getters['articles/single']({ id: params.id });
        return article ? article.type : '';
      }
      if (name === 'category') {
        return params.type;
      }
      return '';
    },
  },
  methods: {
    selectWorld(id) {
      this.$store.commit('worlds/select', id);
    },
    toggleTheme(theme) {
      this.$store.commit('ui/setTheme', theme);
    },
  },
};
</script>

<style lang="scss" scoped>

.logowrap {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.links {
  padding: 16px;
  margin: 0;

  > li a {
    @include text-menu;
    color: var(--color-text);
    display: flex;
    align-items: center;
    padding: 0 12px;
    border-radius: 6px;
    height: 36px;

    &.active {
      @include text-menu-highlight;
      background: var(--color-menu-background-highlight);
    }
  }
}

.link-icon {
  font-size: 18px;
  margin-right: 8px;
}

.themeselect {
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme {
  border: 0;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid var(--color-text-faded);

  & + & {
    margin-left: 16px;
  }

  &-default {
    background: #fff;
  }
  &-purple {
    background: #3f2b54ff;
  }
  &-dark {
    background: #283d52ff;
  }
}
</style>
