import axios from 'axios';
import { createStore } from 'vuex';
import api from './plugins/api';

import auth from './modules/auth';
import articles from './modules/articles';
import categories from './modules/categories';
import worlds from './modules/worlds';
import relations from './modules/relations';
import ui from './modules/ui';

if (!window.Cypress) {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
}

export default createStore({
  modules: {
    auth,
    articles,
    categories,
    worlds,
    relations,
    ui,
  },
  plugins: [api],
});
