<template>
  <div data-test="modal-welcome">
    <form @submit.prevent="handleSubmit">
      <h1>Hello adventurer!</h1>
      <input name="name" v-model="name" />
      <button type="submit">Create my world</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
    };
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch('worlds/create', this.name);
    },
  },
};
</script>
