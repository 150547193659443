import axios from 'axios';

export default {
  namespaced: true,
  state: {
    loggedIn: !!window.localStorage.getItem('access_token'),
  },
  getters: {
    isLoggedIn(state) {
      return state.loggedIn;
    },
  },
  mutations: {
    setToken(state, token) {
      console.log('Setting token', token);
      state.loggedIn = true;
      window.localStorage.setItem('access_token', token);
    },
    clearToken(state) {
      state.loggedIn = false;
      window.localStorage.clear('access_token');
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      const { data } = await axios.post('/auth/login', { email, password });
      commit('setToken', data.access_token);
    },
  },
};
