import axios from 'axios';

export default {
  namespaced: true,
  state: {
    all: [],
    types: [
      { key: 'member:from', label: 'Members' },
      { key: 'member:to', label: 'Member of' },
      { key: 'citizen:from', label: 'Citizens' },
      { key: 'citizen:to', label: 'Citizen of' },
      { key: 'owner:from', label: 'Owners' },
      { key: 'owner:to', label: 'Owner of' },
    ],
  },
  getters: {
    with(state) {
      return ({ id }) => state.all.filter((item) => item.from === id || item.to === id);
    },
    types(state) { return state.types; },
  },
  mutations: {
    setAll(state, all) {
      state.all = all;
    },
  },
  actions: {
    async with_fetch({ commit }, { id }) {
      const { data } = await axios.get(`/relations/${id}`);
      commit('setAll', data.relations);
      return { items: data.relations };
    },
  },
};
