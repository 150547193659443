<template>
  <div class="main-layout">
    <main-navigation class="navigation" />
    <div class="scrollview">
      <router-view />
    </div>
  </div>
</template>

<script>
import MainNavigation from './MainNavigation.vue';

export default {
  components: { MainNavigation },
};
</script>

<style lang="scss" scoped>
.main-layout {
  display: flex;
  height: 100vh;
  background: var(--color-background);
}

.navigation {
  width: 240px;
  height: 100%;
  background: var(--color-menu-background);
}

.scrollview {
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 72px 64px;
  position: relative;
}
</style>
