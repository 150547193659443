export default {
  namespaced: true,
  state: {
    all: [
      { key: 'default', label: 'Articles', icon: 'article' },
      { key: 'settlement', label: 'Settlements', icon: 'location_city' },
      { key: 'area', label: 'Areas', icon: 'public' },
      { key: 'landmark', label: 'Landmarks', icon: 'landscape' },
      { key: 'poi', label: 'Places of Interest', icon: 'place' },
      { key: 'shop', label: 'Businesses', icon: 'store' },
      { key: 'npc', label: 'NPCs', icon: 'face' },
      { key: 'group', label: 'Groups & Factions', icon: 'groups' },
      { key: 'event', label: 'Events', icon: 'hourglass_empty' },
      { key: 'item', label: 'Items & Artifacts', icon: 'auto_fix_high' },
      { key: 'map', label: 'Maps', icon: 'public' },
    ],
  },
  getters: {
    all(state) { return state.all; },
  },
  actions: {
  },
};
