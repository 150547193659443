import { Factory } from 'miragejs';
import faker from 'faker';
import { v4 as uuidv4 } from 'uuid';

export default {
  article: Factory.extend({
    type: 'default',
    title: () => faker.lorem.words(),
    updatedAt: (new Date()).toISOString(),
    test: () => uuidv4(),
    sections: [],
  }),
  world: Factory.extend({
    name: () => faker.address.city(),
    description: () => faker.lorem.sentences(),
    thumbnail: () => faker.image.nature(),
  }),
};
